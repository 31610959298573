<template>
  <div>
    <p class="compont_p">收货地址</p>
    <div class="form_wrap">
      <el-form label-width="100px" :model="form">
        <el-form-item label="姓名：">
          <el-input v-model="form.name" disabled placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="收货地址：">
          <el-input v-model="form.address_str" disabled placeholder="请输入收货地址"></el-input>
        </el-form-item>
        <el-form-item label="手机号码：">
          <el-input v-model="form.phone" disabled placeholder="请输入手机号码"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <p class="tips">*注：按国家GSP规定，收货地址需和药品经营许可证上地址一致，如需变更请联系专属客服！</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        address_str: "",
        phone: "",
      },
    };
  },
  created() {
    this.$api("account.getAddress", { id: 1 }).then((res) => {
      this.form = res.data;
    });
  },
};
</script>

<style lang="less" scoped>
.el-input {
  width: 500px;
}
.form_wrap {
  margin-left: 20px;
  margin-top: 20px;
}
.tips {
  color: @priceRed;
}
</style>
